import React, { Component } from 'react'
import FontAwesome from 'react-fontawesome';
import './styles.scss'

class ContactForm extends Component {
  state = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    message:"",
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const document = this.props.doc;
    const {name, email, phone, subject, message} = this.state;

    const messageBox = () => {
      if (this.state.subject === "Issues") {
        return (
          <h4>Please let us know your address, unit number and what the issue is.</h4>
        )
      } else if (this.state.subject === "Quotes") {
        return (
          <h4>Please let us know what you are looking to get a quote on.</h4>
        )
      } else {
        return (
          <h4>Please let us know any general questions or comments.</h4>
        )
      }
    };

    return (
      <form className='contactForm formContainer' name='contact' id="contact" action="https://formspree.io/info@alllcare.com" method="POST">
        <div className='formSection'>
          <div className='sectionTitle'>
            <h2>Contact Us</h2>
          </div>
          <div className='sectionInputs'>
            {document.contact_links.map((link, i) => {
              return (
                link.social_type === 'Email' ?
                <p key={i}><FontAwesome name='envelope' className='contactIcon' />
                  {link.social_type}: <a href={link.contact_info}>{link.contact_info}</a>
                </p>
                :
                <p key={i}><FontAwesome name='phone' className='contactIcon' />
                  {link.social_type}: <a href={link.contact_info}>{link.contact_info}</a>
                </p>
              )
            })}
          </div>
        </div>
        <div className='formSection'>
          <div className='sectionTitle'>
            <h2>Tell us who you are</h2>
          </div>
          <div className='sectionInputs'>
            <div className='input'>
              <label htmlFor='name'>Full Name</label>
              <input type='text' id='name' name='name' value={name} onChange={this.handleChange}required/>
            </div>
            <div className='input'>
              <label htmlFor='email' >Email Address</label>
              <input type='email' id='email' name='email' value={email} onChange={this.handleChange} required/>
            </div>
            <div className='input'>
              <label htmlFor='phone'>Phone Number</label>
              <input type='tel' id='phone' name='phone' value={phone} onChange={this.handleChange} required/>
            </div>
          </div>
        </div>
        <div className='formSection'>
          <div className='sectionTitle'>
            <h2>How can we help you?</h2>
          </div>
          <div className='sectionInputs'>
            <div className='input'>
              <label htmlFor='subject' >Subject</label>
              <select className='selectDropdown' id='subject' name="subject" value={subject} onChange={this.handleChange} required>
                <option name="subject" value="None" onChange={this.handleChange} defaultValue></option>
                <option name="subject" value="General" onChange={this.handleChange}>General Questions</option>
                <option name="subject" value="Quotes" onChange={this.handleChange}>Quotes</option>
                <option name="subject" value="Issues" onChange={this.handleChange}>Issues</option>
              </select>
            </div>
          </div>
        </div>
        <div className='formSection'>
          <div className='sectionTitle'>
            <h2>Message</h2>
          </div>
          <div className='sectionInputs'>
            <textarea type='text' id='message' className='formMessage' name="message" value={message} onChange={this.handleChange} required></textarea>
            {messageBox()}
          </div>
        </div>
        <div className='formBtnContainer' >
          <button type="submit">Submit</button>
        </div>
      </form>
    );
  }
}

export default ContactForm;
