import React from 'react'
import './styles.scss'

const PageTitle = (props) =>
  <div className='pageTitleContainer'>
    <h1>{props.title}</h1>
    {props.text ? <p>{props.text}</p> : null}
  </div>

export default PageTitle
